import React from 'react'

import { Link } from 'gatsby'

import * as styles from './styles.module.scss'

import tbdImage from '../../../assets/images/png/tbd.png'
import Seo from '../../Seo'

const TbdBlock = () => (
  <>
    <Seo
      title="TBD"
      htmlAttributes={{
        lang: 'en',
      }}
      url="https://www.it-justice.com/"
      description="we are still developing the site for this while these pages are not available"
    />
    <section className={styles.tbdBlock}>
      <div className={styles.tbdBlockContainer}>
        <img src={tbdImage} alt="To Be Decided" />
        <div className={styles.tbdBlockContainerText}>
          <span className={styles.tbdBlockContainerTextSpan}>
            Now the work is in progress.
          </span>
          <span className={styles.tbdBlockContainerTextSpan}>
            Follow for our social networks to stay up to date with all the
            updates.
          </span>
        </div>
        <div className={styles.tbdBlockContainerLinkButton}>
          <Link
            to="/contacts"
            className={styles.tbdBlockContainerLinkButtonLink}
          >
            Contact
          </Link>
        </div>
      </div>
    </section>
  </>
)

export default React.memo(TbdBlock)
